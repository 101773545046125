<template>
  <span>
    <div v-if="logovan" id="admin">
      <button id="btn-logout" class="dugme" @click="logout()"><span class="fa fa-sign-out-alt"></span> Izloguj se</button>
      <h1>Novi članak</h1>
      <div id="novi-clanak">
        <label>Naslov</label>
        <input class="input-naslov"  v-model="novi.naslov">
        <label>Slika (link)</label>
        <input class="input-slika" v-model="novi.slika">
        <label>Kratak opis</label>
        <input v-model="novi.opis">
        <label>Tekst</label>
        <!-- <textarea class="input-tekst" rows="12" v-model="novi.tekst"></textarea> -->
        <editor 
          v-model="novi.tekst"
          apiKey="b7z93gh4epyxf0kk0lzo2rgr1tsfp07wax9ngse9hj6c5c5q"
          initialValue="<p>Initial editor content</p>"
          :init="{ 
            selector: 'input',
            width: '100%',
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap',
              'searchreplace visualblocks code fullscreen',
              'print preview anchor insertdatetime media',
              'paste code help wordcount table'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent | image | help'
          }"
        >
        </editor>
        <button @click="noviClanak()" class="dugme">Sačuvaj</button>
      </div>
      <hr style="width:100%">
      <div id="svi-clanci">
        <h1 style="margin:30px; margin-bottom:40px;">Izmeni članak</h1>
        <div class="clanak" v-for="clanak in novosti" :key="clanak.id">
          <div class="clanak-podaci">
            <img :src="clanak.slika">
            <h3>{{clanak.naslov}}</h3>
            <button class="dugme-izmeni" @click="(function(){clanak.izmeni=!clanak.izmeni})()"><span class="fa fa-pen"></span></button>
            <button  v-if="clanak.obrisi==1" class="dugme-obrisi2" @click="obrisiClanak(clanak)"><span class="fa fa-trash"></span> <p>pritisnite još jednom da obrišete</p></button>
            <button v-else class="dugme-obrisi" @click="obrisi(clanak)"><span class="fa fa-trash"></span></button>
            </div>
        <div class="izmeni-clanak" v-if="clanak.izmeni">
          <label>Naslov</label>
          <input class="input-naslov"  v-model="clanak.naslov">
          <label>Slika (link)</label>
          <input class="input-slika" v-model="clanak.slika">
          <label>Kratak opis</label>
          <input v-model="clanak.opis">
          <label>Tekst</label>
          <!-- <textarea class="input-tekst" rows="12" v-model="novi.tekst"></textarea> -->
          <editor 
            v-model="clanak.tekst"
            apiKey="b7z93gh4epyxf0kk0lzo2rgr1tsfp07wax9ngse9hj6c5c5q"
            initialValue="<p>Initial editor content</p>"
            :init="{ 
              selector: 'input',
              width: '100%',
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap',
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table'
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | image | help'
            }"
          >
          </editor>
          <button @click="izmeniClanak(clanak)" class="dugme">Sačuvaj izmene</button>
        </div>
        </div>
      </div>
      
      <hr style="width:100%">
      <div id="promeni-lozinku">
        <h1 style="margin:30px; margin-bottom:40px;">Promeni lozinku</h1>
          <label>Stara lozinka</label>
          <input type="password"  v-model="staraLozinka">
          <label>Nova lozinka</label>
          <input type="password"  v-model="novaLozinka">
          <label>Ponovi lozinku</label>
          <input type="password"  v-model="novaLozinka2">
          <button @click="promeniLozinku()" class="dugme">Promeni lozinku</button>
      </div>

      <hr style="width:100%">

      <div id="cenovnik">
        <h1 style="margin:30px; margin-bottom:40px;">Novi cenovnik</h1>
        <form :action="apiRoot+'/file-upload.php'" formtarget="_blank" target="_blank" method="post" enctype="multipart/form-data">
          Izaberite fajl u PDF formatu:
          <input type="file" name="fileToUpload" id="fileToUpload">
          <input type="submit" value="Sačuvaj" name="submit">
        </form>
      </div>
    </div>
    <div v-else id="admin-login">
      <p>Admin panel</p>
      <input type="password" v-model="password"  v-on:keyup.enter="login">
      <button @click="login()" class="dugme">Prijavi se</button>
    </div>
  </span>
</template>

<script>
import axios from '@/axios';
import Editor from '@tinymce/tinymce-vue'


export default {
  name: 'Admin',
  components: {
    editor: Editor
  },
  data: function(){
    return(
      {
        apiRoot:process.env.VUE_APP_API_URL,
        staraLozinka:"",
        novaLozinka:"",
        novaLozinka2:"",
        password:"",
        jwt: localStorage.getItem('jwt'),
        logovan: false,
        novi:{
          naslov:"",
          slika:"",
          opis:"",
          tekst:""
        },
        novosti: []
      }
    );
  },
  methods:{
    logout(){
      localStorage.removeItem('jwt');
      this.logovan=false;
    },
    login(){
      let self=this;
      let params = new URLSearchParams();
      params.append('password', this.password);
      // this.password="";
      axios.post('login.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        localStorage.setItem('jwt', response.data);
        self.jwt=response.data;
        self.logovan=true;
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    },
    verifyToken(){
      let self=this;
      let params = new URLSearchParams();
      params.append('jwt', this.jwt);
      axios.post('verify-token.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        self.logovan=true;
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        localStorage.removeItem('jwt');
      });
    },
    getData(){
      let self=this;
      axios.get('novosti.php').then(function (response) {
        console.log("OK! ", response, response.data);
        self.novosti=response.data;
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
      });
    },
    noviClanak(){
      let self=this;
      let params = new URLSearchParams();
      params.append('clanak', JSON.stringify(this.novi));
      params.append('jwt', this.jwt);
      axios.post('novi-clanak.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        self.novi={naslov:"",slika:"",tekst:""};
        window.alert("Sačuvano");
        self.getData();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    }, 
    izmeniClanak(clanak){
      let self=this;
      let params = new URLSearchParams();
      params.append('clanak', JSON.stringify(clanak));
      params.append('jwt', this.jwt);
      axios.post('izmeni-clanak.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        window.alert("Izmene sačuvane");
        self.getData();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    }, 
    obrisi(clanak)
    {
      clanak.obrisi=1;
      setTimeout(()=>clanak.obrisi=0,2000);
    },
    obrisiClanak(clanak){
      if(clanak.obrisi!=1)
      {
        clanak.obrisi=1;
        return;
      }
      let self=this;
      let params = new URLSearchParams();
      params.append('id', clanak.id);
      params.append('jwt', this.jwt);
      axios.post('obrisi-clanak.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        window.alert("Članak obrisan");
        self.getData();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    },
    promeniLozinku(){
      if(this.novaLozinka!=this.novaLozinka2)
      {
        window.alert("Unete lozinke se razlikuju");
        return;
      }
      let params = new URLSearchParams();
      params.append('stara', this.staraLozinka);
      params.append('nova', this.novaLozinka);
      params.append('jwt', this.jwt);
      axios.post('promeni-lozinku.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        window.alert("Lozinka promenjena");
        self.getData();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.file=files[0];
    },
    uploadFile(){
      let params = new URLSearchParams();
      axios.post('file-upload.php', params).then(function (response) {
        console.log("OK! ", response, response.data);  
        window.alert("Cenovnik uspešno sačuvan");
        self.getData();
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
        window.alert(error.response.data);
      });
    }
  },
  mounted(){
    this.verifyToken();
    this.getData();
  }  
}
</script>

<style scoped>

#admin-login{
  height:100vh;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  background-image: url("../assets/bg-04.jpg");
  background-blend-mode:soft-light;
  background-color: rgba(255, 255, 255, 0.493);
}

#admin-login button{
  width:300px;
  margin: 10px;
  font-size:20px;
  height: 40px;
}

#admin-login input{
  padding: 15px;
  height:30px;
  width:300px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  border-width:2px;
  border-style:solid;
  border-color: rgb(131, 125, 125);
}

#admin-login p{
  font-size:50px;
}

#admin{
  padding:50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  background-color: #dcdcdc;

}

#novi-clanak{
  display:flex;
  flex-direction: column;
  width: 60%;
  align-items: flex-start;
}

label{
  padding:5px;
  font-size: 20px;
}

input, textarea{
  width:100%;
  margin-bottom:5px;
}



.tox{
  width:100%;
  margin-bottom:5px;
}

.dugme{
  align-self: center;
}




#svi-clanci{
  width:100%;
  display:flex;
  flex-direction: column;
  /* align-items: center; */
}

.clanak-podaci{
  display:flex;
  align-items: center;
  justify-content: flex-start;
}

.clanak{
  display:flex;
  flex-direction: column;
  
  padding:20px;
  border-width: 0.1px;
  border-style: solid;
  border-radius: 20px;
  
  margin-bottom:30px;
}

.izmeni-clanak{
  width:60%;
  margin-top:40px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.clanak img{
  width:200px;
  margin-right:20px;
}

.clanak h3{
  margin-right:20px;
}

.dugme-izmeni{
  background-color: #f5871eff;
  width:50px;
  height:50px;
}

.dugme-obrisi{
  margin-left:20px;
  background-color: red;
  width:50px;
  height:50px;
}

.dugme-obrisi2{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-left:20px;
  background-color:red;
  height:50px;
}

.dugme-obrisi2 p{
  margin:0;
  margin-left:10px;
  font-size: 20px;
  color:whitesmoke;
}

#promeni-lozinku{
  display:flex;
  flex-direction: column;
  align-items: center;
}

#promeni-lozinku input{
  width:300px;
}

#promeni-lozinku button{
  width:200px;
  margin-top:30px;
  font-size:20px;
}

#btn-logout{
  align-self: flex-end;
  width:180px;
  height:40px;
  margin:0;
  font-size:20px;
}

</style>